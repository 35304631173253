export const FgameData = [
    {
        name: "Aviator",
        logo: "https://sitethemedata.com/casino_icons/fantasy/aviator.png",
        filterType: "SPB",
    },
    {
        name: "Relex",
        logo: "https://cf-cdn.relax-gaming.com/static/img/logo.svg",
        filterType: "RLX",
    },
    {
        name: "Thunder Kick",
        logo: "https://www.thunderkick.com/wp-content/uploads/2021/04/thunderkick-logo-horizontal-white.svg",
        filterType: "TK",
    },
    {
        name: "Nolimit City",
        logo: "https://www.nolimitcity.com/img/site-img/nolimit-city.png",
        filterType: "NLC",
    },
];