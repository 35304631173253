import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GameAPI } from "../../apis/gameAPI";
import BannerList from "../../component/BannerSection/BannerList";
import moment from "moment";
import "./InPlay.css";
// import NewLunch from "../Newlunch/NewLunch";
import LiveCasinoHome from "../../CasinoHome/LiveCasinoHome";
import FantasyGamesHome from "../../CasinoHome/FantasyGamesHome";
import SlotHome from "../../CasinoHome/SlotHome";
import LotteryHome from "../../CasinoHome/LotteryHome";
// import SuperNowaHome from "../../CasinoHome/SuperNowaHome";
import { CasinoApi } from "../../apis/CasinoApi";
import LatestEvent from "../../common/LatestEvent";
import Slot from "../../component/Items/Slot/Slot";
import FantsyList from "../fantasyGame/FantsyList";

const InPlay = ({ casinoAllow }) => {
  const [gameName, setGameName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [MatchListLength, setMatchListLength] = useState();
  const [activeSportList, setactiveSportList] = useState({});
  const [gameIdForItemPage, setgameIdForItemPage] = useState(4);
  const [active, setActive] = useState(4);

  useEffect(() => {
    GameAPI.ACTIVE_SPORT_LIST().then((res) => {
      setactiveSportList(res);
    });
    setIsLoading(true);

    axios
      .get("https://oddsapi.247idhub.com/betfair_api/active_match")
      .then((res) => {
        setGameName(res?.data?.data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setMatchListLength(
      gameName &&
        gameName?.find((item) => item?.sportid === gameIdForItemPage)?.matchList
          ?.length
    );
  }, [
    gameName &&
      gameName?.find((item) => item?.sportid === gameIdForItemPage)?.matchList,
  ]);

  const handleSportId = (id) => {
    setgameIdForItemPage(id);
    setActive(id);
  };

  const nav = useNavigate();

  const handleData = (id) => {
    nav(`/gamedetail/${id}`);
  };

  const [providerList, setProviderList] = useState({});
  const token = localStorage.getItem("token")
  useEffect(()=>{
    if(token !== null){
      CasinoApi.ProvideList({
        gameType:"ALL"
      }).then((res)=>{
        setProviderList(res?.data?.data)
      })
    }
   
  }, [token])

  return (
    <div className="main">
        {/* <LatestEvent iplPath="/gamedetail/28127348" elePath="/gamedetail/1706456690"/> */}

      <div className="container-fluid container-fluid-5">
        <div className="row itemHome">
          <div className="header-btm">
            <nav className="navbar navbar-expand-md top_navbar btco-hover-menu">
              <div className="collapse navbar-collapse">
                <ul className="list-unstyled nav_desk ">
                  {activeSportList?.length &&
                    activeSportList?.map((res) => {
                      return (
                        <li
                          className="nav-item"
                          onClick={() => handleSportId(res?.sportId)}>
                          <Link
                            className={`nav-pills nav-b ${
                              active === res?.sportId ? "activeNav" : ""
                            }`}>
                            {res?.sportName}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </nav>
            {localStorage.getItem("token") === null ? <BannerList /> : ""}
          </div>
          <div>
            <>
              <div className="tab-content">
                <div className="tab-pane">
                  <div className="coupon-card coupon-card-first">
                    <div className="card-content">
                      <table className="table item-table coupon-table change_background">
                        <thead>
                          <tr className="bet-table-header">
                            <th
                              style={{
                                width: "63%",
                                fontSize: "14px",
                                fontWeight: "900",
                              }}>
                              <b>Game</b>
                            </th>
                            <th colSpan="2">1</th>
                            <th colSpan="2">X</th>
                            <th colSpan="2">2</th>
                          </tr>
                        </thead>
                        {isLoading ? (
                          <p className="lodder homePage-lodder">
                            <i className="fa fa-spinner fa-spin"></i>
                          </p>
                        ) : (
                          <tbody
                            className={`${
                              MatchListLength !== 0 ? "" : "d-none"
                            }`}>
                            {gameName?.find(
                              (item) => item?.sportid === gameIdForItemPage
                            )?.matchList?.length > 0
                              ? gameName?.length > 0
                                ? gameName
                                    ?.find(
                                      (item) =>
                                        item?.sportid === gameIdForItemPage
                                    )
                                    ?.matchList?.map((item) => {
                                      return (
                                        <tr key={item.matchId}>
                                          <td>
                                            <div
                                              className="game-name gName"
                                              onClick={() =>
                                                handleData(item?.matchId)
                                              }>
                                              <Link className="text-dark">
                                                {item.matchName} /{" "}
                                                {moment(item.openDate).format(
                                                  "MMM DD YYYY h:mm a"
                                                )}
                                              </Link>
                                            </div>
                                            <div className="game-icons gIcon">
                                              <span className="game-icon">
                                                <span
                                                  className={
                                                    item.inPlay === false
                                                      ? ""
                                                      : "active"
                                                  }>
                                                  {" "}
                                                </span>
                                              </span>
                                              <span className="game-icon">
                                                <i className="fa fa-tv v-m icon-tv"></i>
                                              </span>
                                              <span className="game-icon">
                                                <img
                                                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_fancy.png"
                                                  alt=""
                                                  className={`fancy-icon ${
                                                    item?.F ? "" : "d-none"
                                                  }`}
                                                />
                                              </span>
                                              <span className="game-icon">
                                                <img
                                                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_bm.png"
                                                  alt=""
                                                  className={
                                                    item.bm
                                                      ? "bookmaker-icon "
                                                      : "d-none"
                                                  }
                                                />
                                              </span>
                                              <span className="game-icon">
                                                <img
                                                  src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_vir.png"
                                                  alt=""
                                                  className="ic-card"
                                                />
                                              </span>
                                            </div>
                                          </td>
                                          <td>
                                            <button className="back">
                                              <span className="odd">
                                                {item.team1Back === 0
                                                  ? "-"
                                                  : item.team1Back}
                                              </span>
                                            </button>
                                          </td>
                                          <td>
                                            <button className="lay">
                                              <span className="odd">
                                                {item.team1Lay === 0
                                                  ? "-"
                                                  : item.team1Lay}
                                              </span>
                                            </button>
                                          </td>
                                          <td>
                                            <button className="back">
                                              <span className="odd">
                                                {item.drawBack === 0
                                                  ? "-"
                                                  : item.drawBack}
                                              </span>
                                            </button>
                                          </td>
                                          <td>
                                            <button className="lay">
                                              <span className="odd">
                                                {item.drawLay === 0
                                                  ? "-"
                                                  : item.drawLay}
                                              </span>
                                            </button>
                                          </td>
                                          <td>
                                            <button className="back">
                                              <span className="odd">
                                                {item.team2Back === 0
                                                  ? "-"
                                                  : item.team2Back}
                                              </span>
                                            </button>
                                          </td>
                                          <td>
                                            <button className="lay">
                                              <span className="odd">
                                                {item.team2Lay === 0
                                                  ? "-"
                                                  : item.team2Lay}
                                              </span>
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                : ""
                              : ""}
                          </tbody>
                        )}

                        <tbody
                          className={`${
                            MatchListLength === 0 ||
                            MatchListLength === undefined
                              ? ""
                              : "d-none"
                          }`}>
                          <tr className="dest_notFound">
                            <td colSpan="6">
                              <p>No Real Data Found</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <FantsyList  showHome={true}/>
      <Slot />
      {/* {(casinoAllow?.Aura || localStorage.getItem("token") === null) && <NewLunch />} */}
      {/* {(casinoAllow?.Nowa || localStorage.getItem("token") === null) && <SuperNowaHome path={"/supernowa"} />} */}
      {/* {(casinoAllow?.Qtech || localStorage.getItem("token") === null) && (
        <>
          <LiveCasinoHome  providerList={providerList?.liveCasino}/>
          <FantasyGamesHome path={"/fantsy"} />
          <SlotHome providerList={providerList}/>
        </>
      )}
      {localStorage.getItem("token") === null && <LotteryHome path={"/lottery"} />} */}
    </div>
  );
};

export default InPlay;
