import React from 'react'
import SportData from '../../Sports/SportData'
import DefauilNav from './DefaultNav'

const DefaultPage = () => {
  return (
    <>
    <DefauilNav/>
    <SportData/>
    </>
  )
}

export default DefaultPage