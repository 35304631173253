export const EvolutionGamming = [
  {
    id: "EVO-dhp",
    name: "2 Hand Casino Hold'em",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-dhp_en_US&type=logo-round&version=1617872650288",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-dhp_en_US&type=banner&version=1617872650162",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-dhp_en_US&type=logo-square&version=1678359084763",
      },
    ],
  },
  {
    id: "EVO-americanroulette",
    name: "American Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-americanroulette_en_US&type=logo-round&version=1610348966728",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-americanroulette_en_US&type=banner&version=1610348969280",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-americanroulette_en_US&type=logo-square&version=1678358704943",
      },
    ],
  },
  {
    id: "EVO-autolightningroulette",
    name: "Auto Lightning Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-autolightningroulette_en_US&type=logo-round&version=1701277570733",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-autolightningroulette_en_US&type=banner&version=1701277571459",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-autolightningroulette_en_US&type=logo-square&version=1701277574098",
      },
    ],
  },
  {
    id: "EVO-autoroulettelapartage",
    name: "Auto-Roulette La Partage",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=logo-round&version=1702450712174",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=banner&version=1702450712120",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=logo-square&version=1702450712159",
      },
    ],
  },
  {
    id: "EVO-bacbo",
    name: "Bac Bo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-bacbo_en_US&type=logo-round&version=1643795586788",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-bacbo_en_US&type=banner&version=1643795587346",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-bacbo_en_US&type=logo-square&version=1678359207526",
      },
    ],
  },
  {
    id: "EVO-baccarat",
    name: "Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-baccarat_en_US&type=logo-round&version=1610349442486",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-baccarat_en_US&type=banner&version=1610349441716",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-baccarat_en_US&type=logo-square&version=1678359251397",
      },
    ],
  },
  {
    id: "EVO-blackjack",
    name: "Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjack_en_US&type=logo-round&version=1610349774440",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjack_en_US&type=banner&version=1610349775444",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjack_en_US&type=logo-square&version=1678359363992",
      },
    ],
  },
  {
    id: "EVO-blackjackvip",
    name: "Blackjack VIP",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjackvip_en_US&type=logo-round&version=1703144169121",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjackvip_en_US&type=banner&version=1703144168529",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-blackjackvip_en_US&type=logo-square&version=1703144173509",
      },
    ],
  },
  {
    id: "EVO-bucharestroulette",
    name: "Bucharest Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-bucharestroulette_en_US&type=logo-round&version=1704270082255",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-bucharestroulette_en_US&type=banner&version=1704270082593",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-bucharestroulette_en_US&type=logo-square&version=1704270085638",
      },
    ],
  },
  {
    id: "EVO-csp",
    name: "Caribbean Stud Poker",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-csp_en_US&type=logo-round&version=1618911309446",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-csp_en_US&type=banner&version=1618911309269",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-csp_en_US&type=logo-square&version=1678359409711",
      },
    ],
  },
  {
    id: "EVO-cashorcrash",
    name: "Cash or Crash",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-cashorcrash_en_US&type=logo-round&version=1635736242502",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-cashorcrash_en_US&type=banner&version=1635736241719",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-cashorcrash_en_US&type=logo-square&version=1678359497656",
      },
    ],
  },
  {
    id: "EVO-holdem",
    name: "Casino Hold'em",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-holdem_en_US&type=logo-round&version=1610350188918",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-holdem_en_US&type=banner&version=1610350191098",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-holdem_en_US&type=logo-square&version=1678390731862",
      },
    ],
  },
  {
    id: "EVO-mvrcophqscoqosd6",
    name: "Casino Malta Dual Play Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-mvrcophqscoqosd6_en_US&type=logo-round&version=1704436149341",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-mvrcophqscoqosd6_en_US&type=banner&version=1704436149470",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-mvrcophqscoqosd6_en_US&type=logo-square&version=1704436151608",
      },
    ],
  },
  {
    id: "EVO-craps",
    name: "Craps",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/CRAPS",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-craps_en_US&type=logo-round&version=1613103926904",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-craps_en_US&type=banner&version=1613103926584",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-craps_en_US&type=logo-square&version=1678360187701",
      },
    ],
  },
  {
    id: "EVO-crazypachinko",
    name: "Crazy Pachinko",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazypachinko_en_US&type=logo-round&version=1709021081010",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazypachinko_en_US&type=banner&version=1709021081118",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazypachinko_en_US&type=logo-square&version=1709021081511",
      },
    ],
  },
  {
    id: "EVO-crazytime",
    name: "Crazy Time",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazytime_en_US&type=logo-round&version=1610351054211",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazytime_en_US&type=banner&version=1610351057754",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-crazytime_en_US&type=logo-square&version=1678378339411",
      },
    ],
  },
  {
    id: "EVO-deadoralivesaloon",
    name: "Dead or Alive: Saloon",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-deadoralivesaloon_en_US&type=logo-round&version=1669711564207",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-deadoralivesaloon_en_US&type=banner&version=1669711579368",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-deadoralivesaloon_en_US&type=logo-square&version=1669711563675",
      },
    ],
  },
  {
    id: "EVO-dealnodeal",
    name: "Deal or No Deal",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-dealnodeal_en_US&type=logo-round&version=1613105019613",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-dealnodeal_en_US&type=banner&version=1613105019350",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-dealnodeal_en_US&type=logo-square&version=1678378487577",
      },
    ],
  },
  {
    id: "EVO-doubleballrou001",
    name: "Double Ball Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-doubleballrou001_en_US&type=logo-round&version=1618910314177",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-doubleballrou001_en_US&type=banner&version=1618910313962",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-doubleballrou001_en_US&type=logo-square&version=1678378966452",
      },
    ],
  },
  {
    id: "EVO-dragontiger",
    name: "Dragon Tiger",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT_DRAGON_TIGER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragontiger_en_US&type=logo-round&version=1610430474672",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragontiger_en_US&type=banner&version=1610430474078",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragontiger_en_US&type=logo-square&version=1678379304276",
      },
    ],
  },
  {
    id: "EVO-dragonaradualplayroulette",
    name: "Dragonara Dual Play Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragonaradualplayroulette_en_US&type=logo-round&version=1704271295480",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragonaradualplayroulette_en_US&type=banner&version=1704271292814",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-dragonaradualplayroulette_en_US&type=logo-square&version=1704271293534",
      },
    ],
  },
  {
    id: "EVO-dreamcatcher",
    name: "Dream Catcher",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-dreamcatcher_en_US&type=logo-round&version=1610436452014",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-dreamcatcher_en_US&type=banner&version=1610436451682",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-dreamcatcher_en_US&type=logo-square&version=1678379368909",
      },
    ],
  },
  {
    id: "EVO-extrachiliepicspins",
    name: "Extra Chilli Epic Spins",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-extrachiliepicspins_en_US&type=logo-round&version=1682408849317",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-extrachiliepicspins_en_US&type=banner&version=1682408872680",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-extrachiliepicspins_en_US&type=logo-square&version=1682408849331",
      },
    ],
  },
  {
    id: "EVO-eth",
    name: "Extreme Texas Hold’em",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-eth_en_US&type=logo-round&version=1613105286898",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-eth_en_US&type=banner&version=1613105286649",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-eth_en_US&type=logo-square&version=1678379518590",
      },
    ],
  },
  {
    id: "EVO-fantan",
    name: "Fan Tan",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-fantan_en_US&type=logo-round&version=1635736513680",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-fantan_en_US&type=banner&version=1635736513206",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-fantan_en_US&type=logo-square&version=1678379688682",
      },
    ],
  },
  {
    id: "EVO-rngbaccarat00000",
    name: "First Person Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbaccarat00000_en_US&type=logo-round&version=1610430630697",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbaccarat00000_en_US&type=banner&version=1610430631537",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbaccarat00000_en_US&type=logo-square&version=1678389202432",
      },
    ],
  },
  {
    id: "EVO-rngbjstandard0",
    name: "First Person Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbjstandard0_en_US&type=logo-round&version=1610431332579",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbjstandard0_en_US&type=banner&version=1610431332520",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngbjstandard0_en_US&type=logo-square&version=1678389402714",
      },
    ],
  },
  {
    id: "EVO-rngcraps00000001",
    name: "First Person Craps",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/CRAPS",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngcraps00000001_en_US&type=logo-round&version=1613105554737",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngcraps00000001_en_US&type=banner&version=1613105554543",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngcraps00000001_en_US&type=logo-square&version=1678389695092",
      },
    ],
  },
  {
    id: "EVO-firstpersondealornodeal",
    name: "First Person Deal or No Deal",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/INSTANTWIN/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersondealornodeal_en_US&type=logo-round&version=1654017699346",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersondealornodeal_en_US&type=banner&version=1654017699872",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersondealornodeal_en_US&type=logo-square&version=1678390660554",
      },
    ],
  },
  {
    id: "EVO-rngdragontiger0",
    name: "First Person Dragon Tiger",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT_DRAGON_TIGER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdragontiger0_en_US&type=logo-round&version=1614084769251",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdragontiger0_en_US&type=banner&version=1614084762149",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdragontiger0_en_US&type=logo-square&version=1678389826687",
      },
    ],
  },
  {
    id: "EVO-rngdreamcatcher",
    name: "First Person Dream Catcher",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/WHEEL_OF_FORTUNE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdreamcatcher_en_US&type=logo-round&version=1613106264516",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdreamcatcher_en_US&type=banner&version=1613106264015",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngdreamcatcher_en_US&type=logo-square&version=1678389965300",
      },
    ],
  },
  {
    id: "EVO-rngtopcard00001",
    name: "First Person Football Studio",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngtopcard00001_en_US&type=logo-round&version=1613106656835",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngtopcard00001_en_US&type=banner&version=1613106656766",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngtopcard00001_en_US&type=logo-square&version=1678390061346",
      },
    ],
  },
  {
    id: "EVO-firstpersongoldenwealthbaccarat",
    name: "First Person Golden Wealth Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersongoldenwealthbaccarat_en_US&type=logo-round&version=1635336512764",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersongoldenwealthbaccarat_en_US&type=banner&version=1635336512515",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersongoldenwealthbaccarat_en_US&type=logo-square&version=1678390223082",
      },
    ],
  },
  {
    id: "EVO-firstpersonhilo",
    name: "First Person HiLo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/INSTANTWIN/HIGHER_LOWER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonhilo_en_US&type=logo-round&version=1720525521292",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonhilo_en_US&type=banner&version=1720525521241",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonhilo_en_US&type=logo-square&version=1720525521181",
      },
    ],
  },
  {
    id: "EVO-firstpersonlightningbaccarat",
    name: "First Person Lightning Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonlightningbaccarat_en_US&type=logo-round&version=1635337092610",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonlightningbaccarat_en_US&type=banner&version=1635337092050",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonlightningbaccarat_en_US&type=logo-square&version=1678390283849",
      },
    ],
  },
  {
    id: "EVO-rngmegaball00001",
    name: "First Person Mega Ball",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LOTTERY/BINGO",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngmegaball00001_en_US&type=logo-round&version=1613106496690",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngmegaball00001_en_US&type=banner&version=1613106495891",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngmegaball00001_en_US&type=logo-square&version=1678390412207",
      },
    ],
  },
  {
    id: "EVO-firstpersonprosperitytreebaccarat",
    name: "First Person Prosperity Tree Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonprosperitytreebaccarat_en_US&type=logo-round&version=1693967585108",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonprosperitytreebaccarat_en_US&type=banner&version=1693967584644",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonprosperitytreebaccarat_en_US&type=logo-square&version=1693967585006",
      },
    ],
  },
  {
    id: "EVO-rngrteuropean0",
    name: "First Person Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngrteuropean0_en_US&type=logo-round&version=1610431712265",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngrteuropean0_en_US&type=banner&version=1610431711901",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-rngrteuropean0_en_US&type=logo-square&version=1678390474746",
      },
    ],
  },
  {
    id: "EVO-firstpersonstockmarket",
    name: "First Person Stock Market",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/INSTANTWIN/HIGHER_LOWER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonstockmarket_en_US&type=logo-round&version=1711531667589",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonstockmarket_en_US&type=banner&version=1711531671769",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonstockmarket_en_US&type=logo-square&version=1711531669658",
      },
    ],
  },
  {
    id: "EVO-firstpersonsupersicbo",
    name: "First Person Super Sic Bo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/SICBO",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonsupersicbo_en_US&type=logo-round&version=1700608692959",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonsupersicbo_en_US&type=banner&version=1700608692923",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonsupersicbo_en_US&type=logo-square&version=1700608692903",
      },
    ],
  },
  {
    id: "EVO-firstpersonvideopoker",
    name: "First Person Video Poker",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonvideopoker_en_US&type=logo-round&version=1698929875401",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonvideopoker_en_US&type=banner&version=1698929874589",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonvideopoker_en_US&type=logo-square&version=1698929877345",
      },
    ],
  },
  {
    id: "EVO-firstpersonxxxtremelightningbaccarat",
    name: "First Person XXXtreme Lightning Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningbaccarat_en_US&type=logo-round&version=1696378374840",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningbaccarat_en_US&type=banner&version=1696378372594",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningbaccarat_en_US&type=logo-square&version=1696378373132",
      },
    ],
  },
  {
    id: "EVO-firstpersonxxxtremelightningroulette",
    name: "First Person XXXtreme Lightning Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/TABLEGAME/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningroulette_en_US&type=logo-round&version=1690309887381",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningroulette_en_US&type=banner&version=1690309892110",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-firstpersonxxxtremelightningroulette_en_US&type=logo-square&version=1690309883611",
      },
    ],
  },
  {
    id: "EVO-footballstudio",
    name: "Football Studio",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudio_en_US&type=logo-round&version=1610437997189",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudio_en_US&type=banner&version=1610437997356",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudio_en_US&type=logo-square&version=1678380086230",
      },
    ],
  },
  {
    id: "EVO-footballstudiodice",
    name: "Football Studio Dice",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudiodice_en_US&type=logo-round&version=1668071085425",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudiodice_en_US&type=banner&version=1668071084598",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudiodice_en_US&type=logo-square&version=1668071084712",
      },
    ],
  },
  {
    id: "EVO-footballstudioroulette",
    name: "Football Studio Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudioroulette_en_US&type=logo-round&version=1668070690556",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudioroulette_en_US&type=banner&version=1668070720025",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-footballstudioroulette_en_US&type=logo-square&version=1668070690609",
      },
    ],
  },
  {
    id: "EVO-freebetblackjack",
    name: "Free Bet VIP Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-freebetblackjack_en_US&type=logo-round&version=1716281579365",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-freebetblackjack_en_US&type=banner&version=1716281579099",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-freebetblackjack_en_US&type=logo-square&version=1716281581599",
      },
    ],
  },
  {
    id: "EVO-funkytime",
    name: "Funky Time",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-funkytime_en_US&type=logo-round&version=1684854024036",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-funkytime_en_US&type=banner&version=1684854076715",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-funkytime_en_US&type=logo-square&version=1684854023539",
      },
    ],
  },
  {
    id: "EVO-goldfrenchroulette",
    name: "Gold French Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldfrenchroulette_en_US&type=logo-round&version=1702450150717",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldfrenchroulette_en_US&type=banner&version=1702450150436",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldfrenchroulette_en_US&type=logo-square&version=1702450150626",
      },
    ],
  },
  {
    id: "EVO-goldvaultroulette",
    name: "Gold Vault Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldvaultroulette_en_US&type=logo-round&version=1693384921031",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldvaultroulette_en_US&type=banner&version=1693384921151",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldvaultroulette_en_US&type=logo-square&version=1693384921227",
      },
    ],
  },
  {
    id: "EVO-goldenwealthbaccarat",
    name: "Golden Wealth Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldenwealthbaccarat_en_US&type=logo-round&version=1635335974635",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldenwealthbaccarat_en_US&type=banner&version=1635335974305",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-goldenwealthbaccarat_en_US&type=logo-square&version=1678382319146",
      },
    ],
  },
  {
    id: "EVO-gonzostreasuremap",
    name: "Gonzo’s Treasure Map",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-gonzostreasuremap_en_US&type=logo-round&version=1693967381330",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-gonzostreasuremap_en_US&type=banner&version=1693967378837",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-gonzostreasuremap_en_US&type=logo-square&version=1693967381346",
      },
    ],
  },
  {
    id: "EVO-grandcasinoroulette",
    name: "Grand Casino Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-grandcasinoroulette_en_US&type=logo-round&version=1716279050512",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-grandcasinoroulette_en_US&type=banner&version=1716279051514",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-grandcasinoroulette_en_US&type=logo-square&version=1716279050836",
      },
    ],
  },
  {
    id: "EVO-hindilightningroulette",
    name: "Hindi Lightning Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindilightningroulette_en_US&type=logo-round&version=1704185587827",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindilightningroulette_en_US&type=banner&version=1704185587341",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindilightningroulette_en_US&type=logo-square&version=1704185593517",
      },
    ],
  },
  {
    id: "EVO-hindispeedbaccarat",
    name: "Hindi Speed Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindispeedbaccarat_en_US&type=logo-round&version=1704186510320",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindispeedbaccarat_en_US&type=banner&version=1704186510163",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-hindispeedbaccarat_en_US&type=logo-square&version=1704186511378",
      },
    ],
  },
  {
    id: "EVO-imperialquest",
    name: "Imperial Quest",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-imperialquest_en_US&type=logo-round&version=1683273337148",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-imperialquest_en_US&type=banner&version=1683273340024",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-imperialquest_en_US&type=logo-square&version=1683273339339",
      },
    ],
  },
  {
    id: "EVO-infiniteblackjack",
    name: "Infinite Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-infiniteblackjack_en_US&type=logo-round&version=1614072777661",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-infiniteblackjack_en_US&type=banner&version=1614072763452",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-infiniteblackjack_en_US&type=logo-square&version=1678382577061",
      },
    ],
  },
  {
    id: "EVO-infinitefreebetblackjack",
    name: "Infinite Free Bet Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-infinitefreebetblackjack_en_US&type=logo-round&version=1704368949631",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-infinitefreebetblackjack_en_US&type=banner&version=1704368949974",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-infinitefreebetblackjack_en_US&type=logo-square&version=1704368949965",
      },
    ],
  },
  {
    id: "EVO-instantroulette",
    name: "Instant Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantroulette_en_US&type=logo-round&version=1614072961587",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantroulette_en_US&type=banner&version=1614072943508",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantroulette_en_US&type=logo-square&version=1678382622270",
      },
    ],
  },
  {
    id: "EVO-lightningbaccarat",
    name: "Lightning Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningbaccarat_en_US&type=logo-round&version=1638521278736",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningbaccarat_en_US&type=banner&version=1638521278190",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningbaccarat_en_US&type=logo-square&version=1678382819308",
      },
    ],
  },
  {
    id: "EVO-lightningdice",
    name: "Lightning Dice",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningdice_en_US&type=logo-round&version=1619764261483",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningdice_en_US&type=banner&version=1619764261335",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningdice_en_US&type=logo-square&version=1678386089419",
      },
    ],
  },
  {
    id: "EVO-lightningsicbo",
    name: "Lightning Sic Bo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/SICBO",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningsicbo_en_US&type=logo-round&version=1718166120153",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningsicbo_en_US&type=banner&version=1718166119714",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningsicbo_en_US&type=logo-square&version=1718166120021",
      },
    ],
  },
  {
    id: "EVO-lightningstorm",
    name: "Lightning Storm",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningstorm_en_US&type=logo-round&version=1722925754470",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningstorm_en_US&type=banner&version=1722925754179",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-lightningstorm_en_US&type=logo-square&version=1722925754420",
      },
    ],
  },
  {
    id: "EVO-monopolybigballer",
    name: "MONOPOLY Big Baller",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopolybigballer_en_US&type=logo-round&version=1661324676634",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopolybigballer_en_US&type=banner&version=1661324677090",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopolybigballer_en_US&type=logo-square&version=1661324677746",
      },
    ],
  },
  {
    id: "EVO-monopoly",
    name: "MONOPOLY Live",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopoly_en_US&type=logo-round&version=1610436984800",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopoly_en_US&type=banner&version=1610436982065",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-monopoly_en_US&type=logo-square&version=1678386679196",
      },
    ],
  },
  {
    id: "EVO-megaball",
    name: "Mega Ball",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-megaball_en_US&type=logo-round&version=1610432055921",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-megaball_en_US&type=banner&version=1610432054977",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-megaball_en_US&type=logo-square&version=1678386402736",
      },
    ],
  },
  {
    id: "EVO-peekbaccarat",
    name: "Peek Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-peekbaccarat_en_US&type=logo-round&version=1658492840862",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-peekbaccarat_en_US&type=banner&version=1658492840515",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-peekbaccarat_en_US&type=logo-square&version=1658492840706",
      },
    ],
  },
  {
    id: "EVO-powerblackjack",
    name: "Power Blackjack",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BLACKJACK",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-powerblackjack_en_US&type=logo-round&version=1614072636187",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-powerblackjack_en_US&type=banner&version=1614072621748",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-powerblackjack_en_US&type=logo-square&version=1678386778112",
      },
    ],
  },
  {
    id: "EVO-prosperitytreebaccarat",
    name: "Prosperity Tree Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-prosperitytreebaccarat_en_US&type=logo-round&version=1693967915759",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-prosperitytreebaccarat_en_US&type=banner&version=1693967915319",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-prosperitytreebaccarat_en_US&type=logo-square&version=1693967915667",
      },
    ],
  },
  {
    id: "EVO-roulette",
    name: "Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-roulette_en_US&type=logo-round&version=1610437327682",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-roulette_en_US&type=banner&version=1610437323618",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-roulette_en_US&type=logo-square&version=1678386957949",
      },
    ],
  },
  {
    id: "EVO-speedbaccarat",
    name: "Speed Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-speedbaccarat_en_US&type=logo-round&version=1703144000481",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-speedbaccarat_en_US&type=banner&version=1703143997675",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-speedbaccarat_en_US&type=logo-square&version=1703143998252",
      },
    ],
  },
  {
    id: "EVO-lkcbrbdckjxajdol",
    name: "Speed Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-lkcbrbdckjxajdol_en_US&type=logo-round&version=1618907055066",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-lkcbrbdckjxajdol_en_US&type=banner&version=1618907054895",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-lkcbrbdckjxajdol_en_US&type=logo-square&version=1678388229869",
      },
    ],
  },
  {
    id: "EVO-instantsupersicbo",
    name: "Speed Super Sic Bo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/SICBO",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantsupersicbo_en_US&type=logo-round&version=1711976270555",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantsupersicbo_en_US&type=banner&version=1711976271393",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-instantsupersicbo_en_US&type=logo-square&version=1711976271908",
      },
    ],
  },
  {
    id: "EVO-stockmarket",
    name: "Stock Market",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/GAME_SHOW",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-stockmarket_en_US&type=logo-round&version=1711530073925",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-stockmarket_en_US&type=banner&version=1711530074155",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-stockmarket_en_US&type=logo-square&version=1711530074789",
      },
    ],
  },
  {
    id: "EVO-superandarbahar",
    name: "Super Andar Bahar",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ANDAR_BAHAR",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-superandarbahar_en_US&type=logo-round&version=1650882561375",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-superandarbahar_en_US&type=banner&version=1650882560341",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-superandarbahar_en_US&type=logo-square&version=1678388339595",
      },
    ],
  },
  {
    id: "EVO-sicbo",
    name: "Super Sic Bo",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/SICBO",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-sicbo_en_US&type=logo-round&version=1610437616377",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-sicbo_en_US&type=banner&version=1610437615953",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-sicbo_en_US&type=logo-square&version=1678388500668",
      },
    ],
  },
  {
    id: "EVO-superspeedbaccarat",
    name: "Super Speed Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-superspeedbaccarat_en_US&type=logo-round&version=1726560416656",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-superspeedbaccarat_en_US&type=banner&version=1726560414763",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-superspeedbaccarat_en_US&type=logo-square&version=1726560414743",
      },
    ],
  },
  {
    id: "EVO-teenpatti",
    name: "Teen Patti",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/TEEN_PATTI",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-teenpatti_en_US&type=logo-round&version=1657611668531",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-teenpatti_en_US&type=banner&version=1657611667975",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-teenpatti_en_US&type=logo-square&version=1657611668715",
      },
    ],
  },
  {
    id: "EVO-thb",
    name: "Texas Hold'em Bonus Poker",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-thb_en_US&type=logo-round&version=1617866715277",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-thb_en_US&type=banner&version=1617866714696",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-thb_en_US&type=logo-square&version=1678388633007",
      },
    ],
  },
  {
    id: "EVO-trp",
    name: "Triple Card Poker",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-trp_en_US&type=logo-round&version=1613107179993",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-trp_en_US&type=banner&version=1613107180080",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-trp_en_US&type=logo-square&version=1678388805357",
      },
    ],
  },
  {
    id: "EVO-turkishlightningroulette",
    name: "Türkçe Lightning Rulet",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-turkishlightningroulette_en_US&type=logo-round&version=1704186675296",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-turkishlightningroulette_en_US&type=banner&version=1704186678383",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-turkishlightningroulette_en_US&type=logo-square&version=1704186680667",
      },
    ],
  },
  {
    id: "EVO-videopoker",
    name: "Video Poker",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/POKER",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-videopoker_en_US&type=logo-round&version=1692772143018",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-videopoker_en_US&type=banner&version=1692772184200",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-videopoker_en_US&type=logo-square&version=1692772144353",
      },
    ],
  },
  {
    id: "EVO-xxxtremelightningbaccarat",
    name: "XXXtreme Lightning Baccarat",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/BACCARAT",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningbaccarat_en_US&type=logo-round&version=1696377902852",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningbaccarat_en_US&type=banner&version=1696377902147",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningbaccarat_en_US&type=logo-square&version=1696377902753",
      },
    ],
  },
  {
    id: "EVO-xxxtremelightningroulette",
    name: "XXXtreme Lightning Roulette",
    provider: {
      id: "EVO",
      name: "Evolution Gaming",
    },
    category: "CASINO/LIVECASINO/ROULETTE",
    images: [
      {
        type: "logo-round",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningroulette_en_US&type=logo-round&version=1658477182322",
      },
      {
        type: "banner",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningroulette_en_US&type=banner&version=1658477183671",
      },
      {
        type: "logo-square",
        url: "https://client.qtlauncher.com/images/?id=EVO-xxxtremelightningroulette_en_US&type=logo-square&version=1658477184294",
      },
    ],
  },
];
export const Ezugi = [
    {
        "id": "EZU-32cards",
        "name": "32 Cards",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/OTHER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-32cards_en_US&type=logo-round&version=1603245912360"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-32cards_en_US&type=banner&version=1603245910685"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-32cards_en_US&type=logo-square&version=1678476881377"
            }
        ]
    },
    {
        "id": "EZU-andarbahar",
        "name": "Andar Bahar",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ANDAR_BAHAR",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-andarbahar_en_US&type=logo-round&version=1573719144549"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-andarbahar_en_US&type=banner&version=1573719144707"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-andarbahar_en_US&type=logo-square&version=1678476979546"
            }
        ]
    },
    {
        "id": "EZU-automaticroulette",
        "name": "Auto Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-automaticroulette_en_US&type=logo-round&version=1716210436760"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-automaticroulette_en_US&type=banner&version=1716210436659"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-automaticroulette_en_US&type=logo-square&version=1716210438504"
            }
        ]
    },
    {
        "id": "EZU-autoroulette1",
        "name": "Auto Roulette 1",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-autoroulette1_en_US&type=logo-round&version=1716214178441"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-autoroulette1_en_US&type=banner&version=1716214179308"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-autoroulette1_en_US&type=logo-square&version=1716214180331"
            }
        ]
    },
    {
        "id": "EZU-baccarat",
        "name": "Baccarat",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarat_en_US&type=logo-round&version=1716211284552"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarat_en_US&type=banner&version=1716211285150"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarat_en_US&type=logo-square&version=1716211287452"
            }
        ]
    },
    {
        "id": "EZU-baccarata",
        "name": "Baccarat A",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarata_en_US&type=logo-round&version=1717130391404"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarata_en_US&type=banner&version=1717130390945"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarata_en_US&type=logo-square&version=1717130391537"
            }
        ]
    },
    {
        "id": "EZU-baccaratb",
        "name": "Baccarat B",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratb_en_US&type=logo-round&version=1717130468558"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratb_en_US&type=banner&version=1717130468143"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratb_en_US&type=logo-square&version=1717130468376"
            }
        ]
    },
    {
        "id": "EZU-baccaratc",
        "name": "Baccarat C",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratc_en_US&type=logo-round&version=1717019219766"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratc_en_US&type=banner&version=1717019257621"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratc_en_US&type=logo-square&version=1717019221447"
            }
        ]
    },
    {
        "id": "EZU-baccaratd",
        "name": "Baccarat D",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratd_en_US&type=logo-round&version=1717019141100"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratd_en_US&type=banner&version=1717019140834"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccaratd_en_US&type=logo-square&version=1717019142707"
            }
        ]
    },
    {
        "id": "EZU-baccarate",
        "name": "Baccarat E",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarate_en_US&type=logo-round&version=1716807935926"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarate_en_US&type=banner&version=1716807936330"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-baccarate_en_US&type=logo-square&version=1716807942182"
            }
        ]
    },
    {
        "id": "EZU-betonteenpatti",
        "name": "Bet on Teen Patti",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/TEEN_PATTI",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-betonteenpatti_en_US&type=logo-round&version=1591687607929"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-betonteenpatti_en_US&type=banner&version=1591687607476"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-betonteenpatti_en_US&type=logo-square&version=1678477362730"
            }
        ]
    },
    {
        "id": "EZU-blackjack",
        "name": "Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjack_en_US&type=logo-round&version=1716210634750"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjack_en_US&type=banner&version=1716210635685"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjack_en_US&type=logo-square&version=1716210635423"
            }
        ]
    },
    {
        "id": "EZU-blackjacka",
        "name": "Blackjack A",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacka_en_US&type=logo-round&version=1716373338790"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacka_en_US&type=banner&version=1716373343250"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacka_en_US&type=logo-square&version=1716373346482"
            }
        ]
    },
    {
        "id": "EZU-blackjackb",
        "name": "Blackjack B",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackb_en_US&type=logo-round&version=1716372551860"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackb_en_US&type=banner&version=1716372553012"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackb_en_US&type=logo-square&version=1716372553605"
            }
        ]
    },
    {
        "id": "EZU-blackjackc",
        "name": "Blackjack C",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackc_en_US&type=logo-round&version=1717130575630"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackc_en_US&type=banner&version=1717130575561"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackc_en_US&type=logo-square&version=1717130575737"
            }
        ]
    },
    {
        "id": "EZU-blackjackd",
        "name": "Blackjack D",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackd_en_US&type=logo-round&version=1716810696442"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackd_en_US&type=banner&version=1716810695767"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackd_en_US&type=logo-square&version=1716810696129"
            }
        ]
    },
    {
        "id": "EZU-blackjacke",
        "name": "Blackjack E",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacke_en_US&type=logo-round&version=1716810432499"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacke_en_US&type=banner&version=1716810432559"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacke_en_US&type=logo-square&version=1716810432874"
            }
        ]
    },
    {
        "id": "EZU-blackjacksalonprive",
        "name": "Blackjack Salon Privé",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacksalonprive_en_US&type=logo-round&version=1636532915459"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacksalonprive_en_US&type=banner&version=1636532915149"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjacksalonprive_en_US&type=logo-square&version=1678479702991"
            }
        ]
    },
    {
        "id": "EZU-blackjackdasorte",
        "name": "Blackjack da Sorte",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackdasorte_en_US&type=logo-round&version=1686896424172"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackdasorte_en_US&type=banner&version=1686896443066"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-blackjackdasorte_en_US&type=logo-square&version=1686896424139"
            }
        ]
    },
    {
        "id": "EZU-casinoholdem",
        "name": "Casino Hold'em",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/POKER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinoholdem_en_US&type=logo-round&version=1573719415693"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinoholdem_en_US&type=banner&version=1573719415523"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinoholdem_en_US&type=logo-square&version=1678479919247"
            }
        ]
    },
    {
        "id": "EZU-casinomarinaandarbahar",
        "name": "Casino Marina Andar Bahar",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ANDAR_BAHAR",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaandarbahar_en_US&type=logo-round&version=1685691011165"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaandarbahar_en_US&type=banner&version=1685691012543"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaandarbahar_en_US&type=logo-square&version=1685691014988"
            }
        ]
    },
    {
        "id": "EZU-casinomarinabaccarata",
        "name": "Casino Marina Baccarat A",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccarata_en_US&type=logo-round&version=1715934976762"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccarata_en_US&type=banner&version=1715934977039"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccarata_en_US&type=logo-square&version=1715934978159"
            }
        ]
    },
    {
        "id": "EZU-casinomarinabaccaratb",
        "name": "Casino Marina Baccarat B",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratb_en_US&type=logo-round&version=1716808492969"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratb_en_US&type=banner&version=1716808493143"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratb_en_US&type=logo-square&version=1716808493958"
            }
        ]
    },
    {
        "id": "EZU-casinomarinabaccaratc",
        "name": "Casino Marina Baccarat C",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratc_en_US&type=logo-round&version=1717018983017"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratc_en_US&type=banner&version=1717018982643"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratc_en_US&type=logo-square&version=1717018982455"
            }
        ]
    },
    {
        "id": "EZU-casinomarinabaccaratd",
        "name": "Casino Marina Baccarat D",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratd_en_US&type=logo-round&version=1716808072696"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratd_en_US&type=banner&version=1716808073286"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinabaccaratd_en_US&type=logo-square&version=1716808081093"
            }
        ]
    },
    {
        "id": "EZU-casinomarinaroulette1",
        "name": "Casino Marina Roulette 1",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette1_en_US&type=logo-round&version=1715595994486"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette1_en_US&type=banner&version=1715595994466"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette1_en_US&type=logo-square&version=1715595995744"
            }
        ]
    },
    {
        "id": "EZU-casinomarinaroulette2",
        "name": "Casino Marina Roulette 2",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette2_en_US&type=logo-round&version=1715684072504"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette2_en_US&type=banner&version=1715684065478"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-casinomarinaroulette2_en_US&type=logo-square&version=1715684065675"
            }
        ]
    },
    {
        "id": "EZU-cricketautoroulette",
        "name": "Cricket Auto Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketautoroulette_en_US&type=logo-round&version=1715593194299"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketautoroulette_en_US&type=banner&version=1715593194874"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketautoroulette_en_US&type=logo-square&version=1715593194602"
            }
        ]
    },
    {
        "id": "EZU-cricketwar",
        "name": "Cricket War",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/WAR",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketwar_en_US&type=logo-round&version=1631859898336"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketwar_en_US&type=banner&version=1631859898160"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-cricketwar_en_US&type=logo-square&version=1678479996071"
            }
        ]
    },
    {
        "id": "EZU-diamondroulette",
        "name": "Diamond Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-diamondroulette_en_US&type=logo-round&version=1709617054121"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-diamondroulette_en_US&type=banner&version=1709617053807"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-diamondroulette_en_US&type=logo-square&version=1709617054191"
            }
        ]
    },
    {
        "id": "EZU-dragontiger",
        "name": "Dragon Tiger",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT_DRAGON_TIGER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontiger_en_US&type=logo-round&version=1716211548589"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontiger_en_US&type=banner&version=1716211549320"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontiger_en_US&type=logo-square&version=1716211550206"
            }
        ]
    },
    {
        "id": "EZU-dragontigerdasorte",
        "name": "Dragon Tiger da Sorte",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT_DRAGON_TIGER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontigerdasorte_en_US&type=logo-round&version=1686899384277"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontigerdasorte_en_US&type=banner&version=1686899384727"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-dragontigerdasorte_en_US&type=logo-square&version=1686899384412"
            }
        ]
    },
    {
        "id": "EZU-ezdealerroletabrasileira",
        "name": "EZ Dealer Roleta Brasileira",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroletabrasileira_en_US&type=logo-round&version=1686290202330"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroletabrasileira_en_US&type=banner&version=1686292206108"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroletabrasileira_en_US&type=logo-square&version=1686290202580"
            }
        ]
    },
    {
        "id": "EZU-ezdealerrouletteenglish",
        "name": "EZ Dealer Roulette English",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerrouletteenglish_en_US&type=logo-round&version=1718686633503"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerrouletteenglish_en_US&type=banner&version=1718686633272"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerrouletteenglish_en_US&type=logo-square&version=1718686633623"
            }
        ]
    },
    {
        "id": "EZU-ezdealerroulettejapanese",
        "name": "EZ Dealer Roulette Japanese",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettejapanese_en_US&type=logo-round&version=1715684473282"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettejapanese_en_US&type=banner&version=1715684473858"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettejapanese_en_US&type=logo-square&version=1715684476317"
            }
        ]
    },
    {
        "id": "EZU-ezdealerroulettemandarin",
        "name": "EZ Dealer Roulette Mandarin",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettemandarin_en_US&type=logo-round&version=1715684555347"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettemandarin_en_US&type=banner&version=1715684555351"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettemandarin_en_US&type=logo-square&version=1715684555488"
            }
        ]
    },
    {
        "id": "EZU-ezdealerroulettethai",
        "name": "EZ Dealer Roulette Thai",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettethai_en_US&type=logo-round&version=1661934836963"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettethai_en_US&type=banner&version=1661934836781"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ezdealerroulettethai_en_US&type=logo-square&version=1661934837735"
            }
        ]
    },
    {
        "id": "EZU-footballautoroulette",
        "name": "Football Auto Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-footballautoroulette_en_US&type=logo-round&version=1718685359674"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-footballautoroulette_en_US&type=banner&version=1718685357387"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-footballautoroulette_en_US&type=logo-square&version=1718685357866"
            }
        ]
    },
    {
        "id": "EZU-goldblackjack1",
        "name": "Gold Blackjack 1",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack1_en_US&type=logo-round&version=1716212363098"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack1_en_US&type=banner&version=1716212356964"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack1_en_US&type=logo-square&version=1716212357654"
            }
        ]
    },
    {
        "id": "EZU-goldblackjack3",
        "name": "Gold Blackjack 3",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack3_en_US&type=logo-round&version=1715683161806"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack3_en_US&type=banner&version=1715683162392"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack3_en_US&type=logo-square&version=1715683163914"
            }
        ]
    },
    {
        "id": "EZU-goldblackjack5",
        "name": "Gold Blackjack 5",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack5_en_US&type=logo-round&version=1716211985964"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack5_en_US&type=banner&version=1716211985805"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack5_en_US&type=logo-square&version=1716211987311"
            }
        ]
    },
    {
        "id": "EZU-goldblackjack6",
        "name": "Gold Blackjack 6",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack6_en_US&type=logo-round&version=1715682965755"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack6_en_US&type=banner&version=1715682966004"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-goldblackjack6_en_US&type=logo-square&version=1715682966108"
            }
        ]
    },
    {
        "id": "EZU-italianroulette",
        "name": "Italian Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-italianroulette_en_US&type=logo-round&version=1716209902968"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-italianroulette_en_US&type=banner&version=1716209902324"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-italianroulette_en_US&type=logo-square&version=1716209905632"
            }
        ]
    },
    {
        "id": "EZU-knockoutbaccarat",
        "name": "Knockout Baccarat",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-knockoutbaccarat_en_US&type=logo-round&version=1697602717923"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-knockoutbaccarat_en_US&type=banner&version=1697602717510"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-knockoutbaccarat_en_US&type=logo-square&version=1697602718060"
            }
        ]
    },
    {
        "id": "EZU-lucky7",
        "name": "Lucky 7",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/HIGHER_LOWER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-lucky7_en_US&type=logo-round&version=1597131909813"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-lucky7_en_US&type=banner&version=1597131908955"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-lucky7_en_US&type=logo-square&version=1678547615073"
            }
        ]
    },
    {
        "id": "EZU-namasteroulette",
        "name": "Namaste Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-namasteroulette_en_US&type=logo-round&version=1709613033636"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-namasteroulette_en_US&type=banner&version=1709613033391"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-namasteroulette_en_US&type=logo-square&version=1709613033837"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccarat",
        "name": "No Commission Baccarat",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarat_en_US&type=logo-round&version=1697632213229"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarat_en_US&type=banner&version=1697632283056"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarat_en_US&type=logo-square&version=1697632215853"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccarata",
        "name": "No Commission Baccarat A",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarata_en_US&type=logo-round&version=1716806927353"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarata_en_US&type=banner&version=1716806927066"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarata_en_US&type=logo-square&version=1716806930121"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccaratb",
        "name": "No Commission Baccarat B",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratb_en_US&type=logo-round&version=1716806429090"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratb_en_US&type=banner&version=1716806431180"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratb_en_US&type=logo-square&version=1716806432012"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccaratc",
        "name": "No Commission Baccarat C",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratc_en_US&type=logo-round&version=1716806789128"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratc_en_US&type=banner&version=1716806789012"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratc_en_US&type=logo-square&version=1716806790491"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccaratd",
        "name": "No Commission Baccarat D",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratd_en_US&type=logo-round&version=1716806598967"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratd_en_US&type=banner&version=1716806599163"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccaratd_en_US&type=logo-square&version=1716806605961"
            }
        ]
    },
    {
        "id": "EZU-nocommissionbaccarate",
        "name": "No Commission Baccarat E",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarate_en_US&type=logo-round&version=1717019347859"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarate_en_US&type=banner&version=1717019351028"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-nocommissionbaccarate_en_US&type=logo-square&version=1717019349948"
            }
        ]
    },
    {
        "id": "EZU-onedayteenpatti",
        "name": "One Day Teen Patti",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/TEEN_PATTI",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-onedayteenpatti_en_US&type=logo-round&version=1630986067103"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-onedayteenpatti_en_US&type=banner&version=1630986065779"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-onedayteenpatti_en_US&type=logo-square&version=1678548013373"
            }
        ]
    },
    {
        "id": "EZU-oracle360roulette",
        "name": "Oracle 360 Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oracle360roulette_en_US&type=logo-round&version=1715593849691"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oracle360roulette_en_US&type=banner&version=1715593849688"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oracle360roulette_en_US&type=logo-square&version=1715593851035"
            }
        ]
    },
    {
        "id": "EZU-oraclerealroulette",
        "name": "Oracle Real Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oraclerealroulette_en_US&type=logo-round&version=1715593261320"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oraclerealroulette_en_US&type=banner&version=1715593262255"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-oraclerealroulette_en_US&type=logo-square&version=1715593264395"
            }
        ]
    },
    {
        "id": "EZU-prestigeautoroulette",
        "name": "Prestige Auto Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-prestigeautoroulette_en_US&type=logo-round&version=1709615917637"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-prestigeautoroulette_en_US&type=banner&version=1709615917147"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-prestigeautoroulette_en_US&type=logo-square&version=1709615917447"
            }
        ]
    },
    {
        "id": "EZU-roletadasorte",
        "name": "Roleta da Sorte",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roletadasorte_en_US&type=logo-round&version=1687160063856"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roletadasorte_en_US&type=banner&version=1687160117359"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roletadasorte_en_US&type=logo-square&version=1687160064041"
            }
        ]
    },
    {
        "id": "EZU-roulette",
        "name": "Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roulette_en_US&type=logo-round&version=1716209402898"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roulette_en_US&type=banner&version=1716209403098"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-roulette_en_US&type=logo-square&version=1716209403914"
            }
        ]
    },
    {
        "id": "EZU-rouletteportomaso2",
        "name": "Roulette Portomaso 2",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rouletteportomaso2_en_US&type=logo-round&version=1702888076519"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rouletteportomaso2_en_US&type=banner&version=1702888153500"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rouletteportomaso2_en_US&type=logo-square&version=1702888079313"
            }
        ]
    },
    {
        "id": "EZU-ruletaa",
        "name": "Ruleta A",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletaa_en_US&type=logo-round&version=1715684234017"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletaa_en_US&type=banner&version=1715684261127"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletaa_en_US&type=logo-square&version=1715684235735"
            }
        ]
    },
    {
        "id": "EZU-ruletadelsol",
        "name": "Ruleta del Sol",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletadelsol_en_US&type=logo-round&version=1615793190188"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletadelsol_en_US&type=banner&version=1615793189776"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ruletadelsol_en_US&type=logo-square&version=1678548401278"
            }
        ]
    },
    {
        "id": "EZU-rumbablackjack",
        "name": "Rumba Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack_en_US&type=logo-round&version=1615793431468"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack_en_US&type=banner&version=1615793431394"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack_en_US&type=logo-square&version=1678548658255"
            }
        ]
    },
    {
        "id": "EZU-rumbablackjack1",
        "name": "Rumba Blackjack 1",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack1_en_US&type=logo-round&version=1716377062208"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack1_en_US&type=banner&version=1716377063221"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack1_en_US&type=logo-square&version=1716377068764"
            }
        ]
    },
    {
        "id": "EZU-rumbablackjack2",
        "name": "Rumba Blackjack 2",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack2_en_US&type=logo-round&version=1716377375519"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack2_en_US&type=banner&version=1716377376545"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack2_en_US&type=logo-square&version=1716377378490"
            }
        ]
    },
    {
        "id": "EZU-rumbablackjack3",
        "name": "Rumba Blackjack 3",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack3_en_US&type=logo-round&version=1716377274447"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack3_en_US&type=banner&version=1716377273799"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack3_en_US&type=logo-square&version=1716377274949"
            }
        ]
    },
    {
        "id": "EZU-rumbablackjack4",
        "name": "Rumba Blackjack 4",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack4_en_US&type=logo-round&version=1716377172419"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack4_en_US&type=banner&version=1716377172642"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-rumbablackjack4_en_US&type=logo-square&version=1716377173361"
            }
        ]
    },
    {
        "id": "EZU-russianpoker",
        "name": "Russian Poker",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/POKER",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-russianpoker_en_US&type=logo-round&version=1694497656414"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-russianpoker_en_US&type=banner&version=1694497654106"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-russianpoker_en_US&type=logo-square&version=1694497654378"
            }
        ]
    },
    {
        "id": "EZU-sicbo",
        "name": "Sic Bo",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/SICBO",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-sicbo_en_US&type=logo-round&version=1607934697999"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-sicbo_en_US&type=banner&version=1607934698227"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-sicbo_en_US&type=logo-square&version=1678548717494"
            }
        ]
    },
    {
        "id": "EZU-skylineroulette",
        "name": "Skyline Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-skylineroulette_en_US&type=logo-round&version=1721812902164"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-skylineroulette_en_US&type=banner&version=1721812901568"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-skylineroulette_en_US&type=logo-square&version=1721812902171"
            }
        ]
    },
    {
        "id": "EZU-spanishroulette",
        "name": "Spanish Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishroulette_en_US&type=logo-round&version=1717018773895"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishroulette_en_US&type=banner&version=1717018773840"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishroulette_en_US&type=logo-square&version=1717018775062"
            }
        ]
    },
    {
        "id": "EZU-spanishunlimitedblackjack",
        "name": "Spanish Unlimited Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishunlimitedblackjack_en_US&type=logo-round&version=1716214772636"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishunlimitedblackjack_en_US&type=banner&version=1716214773207"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-spanishunlimitedblackjack_en_US&type=logo-square&version=1716214777341"
            }
        ]
    },
    {
        "id": "EZU-speedautoroulette",
        "name": "Speed Auto Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedautoroulette_en_US&type=logo-round&version=1715596305924"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedautoroulette_en_US&type=banner&version=1715596550998"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedautoroulette_en_US&type=logo-square&version=1715596305794"
            }
        ]
    },
    {
        "id": "EZU-speedroulette",
        "name": "Speed Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedroulette_en_US&type=logo-round&version=1709614345016"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedroulette_en_US&type=banner&version=1709614345066"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-speedroulette_en_US&type=logo-square&version=1709614344882"
            }
        ]
    },
    {
        "id": "EZU-super6baccarat",
        "name": "Super 6 Baccarat",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BACCARAT",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-super6baccarat_en_US&type=logo-round&version=1697601820449"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-super6baccarat_en_US&type=banner&version=1697601820246"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-super6baccarat_en_US&type=logo-square&version=1697601820717"
            }
        ]
    },
    {
        "id": "EZU-teenpatti",
        "name": "Teen Patti 3 Card",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/TEEN_PATTI",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-teenpatti_en_US&type=logo-round&version=1711978067773"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-teenpatti_en_US&type=banner&version=1711978068190"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-teenpatti_en_US&type=logo-square&version=1711978068263"
            }
        ]
    },
    {
        "id": "EZU-turkishroulette",
        "name": "Turkish Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishroulette_en_US&type=logo-round&version=1716209761458"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishroulette_en_US&type=banner&version=1716209761888"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishroulette_en_US&type=logo-square&version=1716209762546"
            }
        ]
    },
    {
        "id": "EZU-turkishunlimitedblackjack",
        "name": "Turkish Unlimited Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishunlimitedblackjack_en_US&type=logo-round&version=1716214592791"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishunlimitedblackjack_en_US&type=banner&version=1716214593113"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-turkishunlimitedblackjack_en_US&type=logo-square&version=1716214593470"
            }
        ]
    },
    {
        "id": "EZU-ultimateandarbahar",
        "name": "Ultimate Andar Bahar",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ANDAR_BAHAR",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateandarbahar_en_US&type=logo-round&version=1669146546849"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateandarbahar_en_US&type=banner&version=1669146546446"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateandarbahar_en_US&type=logo-square&version=1669146546695"
            }
        ]
    },
    {
        "id": "EZU-ultimateroulette",
        "name": "Ultimate Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/GAME_SHOW",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateroulette_en_US&type=logo-round&version=1680097971109"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateroulette_en_US&type=banner&version=1680097969811"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimateroulette_en_US&type=logo-square&version=1680097969157"
            }
        ]
    },
    {
        "id": "EZU-ultimatesicbo",
        "name": "Ultimate Sic Bo",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/SICBO",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimatesicbo_en_US&type=logo-round&version=1638523353183"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimatesicbo_en_US&type=banner&version=1638523352698"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-ultimatesicbo_en_US&type=logo-square&version=1678549366610"
            }
        ]
    },
    {
        "id": "EZU-unlimitedblackjack",
        "name": "Unlimited Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack_en_US&type=logo-round&version=1716210779582"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack_en_US&type=banner&version=1716210779099"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack_en_US&type=logo-square&version=1716210779630"
            }
        ]
    },
    {
        "id": "EZU-unlimitedblackjack1",
        "name": "Unlimited Blackjack 1",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack1_en_US&type=logo-round&version=1716214477021"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack1_en_US&type=banner&version=1716214477871"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-unlimitedblackjack1_en_US&type=logo-square&version=1716214480153"
            }
        ]
    },
    {
        "id": "EZU-vipdiamondblackjack",
        "name": "VIP Diamond Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipdiamondblackjack_en_US&type=logo-round&version=1716215098437"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipdiamondblackjack_en_US&type=banner&version=1716215100642"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipdiamondblackjack_en_US&type=logo-square&version=1716215100014"
            }
        ]
    },
    {
        "id": "EZU-viproulette",
        "name": "VIP Roulette",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/ROULETTE",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-viproulette_en_US&type=logo-round&version=1715596170447"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-viproulette_en_US&type=banner&version=1715596169812"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-viproulette_en_US&type=logo-square&version=1715596170509"
            }
        ]
    },
    {
        "id": "EZU-vipsurrenderblackjack",
        "name": "VIP Surrender Blackjack",
        "provider": {
            "id": "EZU",
            "name": "Ezugi"
        },
        "category": "CASINO/LIVECASINO/BLACKJACK",
        "images": [
            {
                "type": "logo-round",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipsurrenderblackjack_en_US&type=logo-round&version=1716214965613"
            },
            {
                "type": "banner",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipsurrenderblackjack_en_US&type=banner&version=1716214973796"
            },
            {
                "type": "logo-square",
                "url": "https://client.qtlauncher.com/images/?id=EZU-vipsurrenderblackjack_en_US&type=logo-square&version=1716214970607"
            }
        ]
    }
]



export const newProvider = [
    {
        "id": 88,
        "gameType": "LIVECASINO",
        "providerName": "Evolution Gaming",
        "currency": "INR",
        "providerId": "EVO",
        "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/EVO.png",
        "gameCode": "EVO-dhp"
    },
    {
        "id": 89,
        "gameType": "LIVECASINO",
        "providerName": "Ezugi",
        "currency": "INR",
        "providerId": "EZU",
        "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/EZU.png",
        "gameCode": "EZU-32cards"
    },
    // {
    //     "id": 90,
    //     "gameType": "LIVECASINO",
    //     "providerName": "BetGames.TV",
    //     "currency": "INR",
    //     "providerId": "BTV",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/BTV.png",
    //     "gameCode": "BTV-lobby"
    // },
    // {
    //     "id": 91,
    //     "gameType": "LIVECASINO",
    //     "providerName": "Ho Gaming",
    //     "currency": "INR",
    //     "providerId": "HOG",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/HOG.png",
    //     "gameCode": "HOG-lobby"
    // },
    // {
    //     "id": 92,
    //     "gameType": "LIVECASINO",
    //     "providerName": "Vivo Gaming",
    //     "currency": "INR",
    //     "providerId": "VGL",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/VGL.png",
    //     "gameCode": "VGL-bulgariaroulette"
    // },
    // {
    //     "id": 93,
    //     "gameType": "LIVECASINO",
    //     "providerName": "Pragmatic Play Live",
    //     "currency": "INR",
    //     "providerId": "PPL",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/PPL.svg",
    //     "gameCode": "PPL-livecasinolobby"
    // },
    // {
    //     "id": 94,
    //     "gameType": "LIVECASINO",
    //     "providerName": "7Mojos Live",
    //     "currency": "INR",
    //     "providerId": "7ML",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/7ML.svg",
    //     "gameCode": "7ML-andarbahar"
    // },
    // {
    //     "id": 95,
    //     "gameType": "LIVECASINO",
    //     "providerName": "BETER Live",
    //     "currency": "INR",
    //     "providerId": "BTL",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/BTL.svg",
    //     "gameCode": "BTL-lobby"
    // },
    // {
    //     "id": 96,
    //     "gameType": "LIVECASINO",
    //     "providerName": "Skywind Live",
    //     "currency": "INR",
    //     "providerId": "SWL",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/SWL.svg",
    //     "gameCode": "SWL-atomroulette"
    // },
    // {
    //     "id": 97,
    //     "gameType": "LIVECASINO",
    //     "providerName": "SA Gaming",
    //     "currency": "INR",
    //     "providerId": "SAG",
    //     "image": "https://s3buket.blr1.cdn.digitaloceanspaces.com/SAG.png",
    //     "gameCode": "SAG-lobby"
    // }
]